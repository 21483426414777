<template>
  <v-menu transition="slide-y-transition" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="v-btn"
        @click="$emit('onClick')"
        depressed>
        {{title}}
        <img class="v-btn-img" :src="'https://cdn4.iconfinder.com/data/icons/simplicio/128x128/notification_add.png'"/>
      </v-btn>
    </template>

    <v-list v-if="menuOptions.length !== 0">
      <v-list-item
        v-for="(menuOption, index) in menuOptions"
        :key="index"
        @click="$emit('onSelectMenuOption', menuOption)"
        link>
        <v-list-item-title>{{ menuOption.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'OrganizzeAddAnyButton',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    menuOptions: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  directives: {},
  components: {},
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {},
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-btn {
    color: $colorPrimary;
    align-self: center;
    font-size: 16px;
    text-transform: uppercase;

    &-img {
      margin: 16px;
      height: 24px;
    }
  }
</style>